import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const SingleFantastTipsSkeleton = ({items}) => {
    return Array(items)
        .fill(0)
        .map((item, i) => (
            <>
                <div className="page-single-fantasy-tips" key={i}>
                    <div className="page-title">
                        <div className="site-container">
                            <h1 className="title"><Skeleton height={30} count={2} /></h1>
                            <p className="date">
                                <span><Skeleton height={14} width={100} /></span>
                            </p>
                            <Skeleton height={3} />
                        </div>
                    </div>
                    <div className="site-container">
                        <div className="tips-main">
                            <div className="featured-image center">
                                <Skeleton height={300} borderRadius={15} />
                            </div>
                            <div className="content-box-wrapper">
                                <Skeleton height={20} width={250} />
                                <Skeleton count={5} />
                            </div>
                            <div className="content-box-wrapper">
                                <Skeleton height={20} width={250} />
                                <Skeleton count={5} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ));
    
}
export default SingleFantastTipsSkeleton;