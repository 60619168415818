import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import CurrentMatchSkeleton from "../../Skeletons/CurrentMatchSkeletor";


export default function CurrentEventMatch() {

    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRecentEventMatches();
    }, [])






    const getRecentEventMatches = async () => {
     
        fetch('https://cricket-experts.cricdiction.com/wp-json/cricdiction/v1/recent-event-matches', { cache: 'no-store' })
        .then(response => response.json())
        .then(matches => {
            setMatches(matches);
            setIsLoading(false);
        })

    }

  return (
    <>
        <div className="header-current-matches">
            <div className="site-container">
                {isLoading && <div className="discussion-skeleton flex justify-between"><CurrentMatchSkeleton items={5} /></div>}

                {
                    matches.length ?
                    <>
                        <Swiper
                            spaceBetween={18}
                            slidesPerView={5}
                            breakpoints={{
                                280: { slidesPerView: 1 },
                                360: { slidesPerView: 1 },
                                540: { slidesPerView: 2 },
                                768: { slidesPerView: 3 },
                                900: { slidesPerView: 5 },
                                1200: { slidesPerView: 5 },
                            }}
                        >
                            {
                            
                                matches.map((match) => (
                                    <SwiperSlide key={match.event_id}>
                                    <div className="current-matches-item">
                                            <p className="date">{match?.match_time}</p>
                                            <p className="subtitle">{match?.match_subtitle}</p>
                                            <div className="team-wrap">
                                                <div className="team-name">
                                                    <img src={match?.home_image ? match?.home_image: "/assets/media/no-flag.png"} alt="" />
                                                    <p className="dark:text-white">{match?.home_team}</p>
                                                </div>
                                                <div className="team-name">
                                                    <img src={match?.away_image ? match?.away_image: "/assets/media/no-flag.png"} alt="" />
                                                    <p className="dark:text-white">{match?.away_team}</p>
                                                </div>
                                            </div>
                                            <div className="current-matches-footer">
                                                <Link to={'/event/' + match?.event_id + '/' + match?.event_slug } className="dark:text-off-white">
                                                    View Prediction
                                                </Link>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            
                            }
                        </Swiper>
                    </>
                    :
                    <div>
                    </div>
                   
                }
            </div>
        </div>
    </>
  )
}
