import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const DiscussionSkeleton = ({items}) => {
    return Array(items)
        .fill(0)
        .map((item, i) => (
            <div className="wrap" key={i} >
                <div className="date"><Skeleton height={30} width={150} /></div> 
                <div className="title"><Skeleton count={4} /></div> 
                <div className="discussion-footer-wrap">
                    <div className="excerpt"><Skeleton width={150} /></div> 
                    <div className="enter"><Skeleton width={70} /></div> 
                </div>
            </div>
        ));
    
}
export default DiscussionSkeleton;