import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
    return (
        
        <ul className="nav-list">
            <li className="nav-item">
                <Link to="/">
                    <img src="/assets/media/menu-icon1.svg" alt="" />
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/">
                    <img src="/assets/media/menu-icon2.svg" alt="" />
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/">
                    <img src="/assets/media/menu-icon3.svg" alt="" />
                </Link>
            </li>
        </ul>
    );
}
export default Menus;