import React from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const FantastTipsSkeleton = ({items}) => {
    return Array(items)
        .fill(0)
        .map((item, i) => (
            <div className="tips-skeleton" key={i}>
                <div className="tips-link">
                    <span><Skeleton height={195} borderRadius={15} /></span>
                    <h2 className="title"><Skeleton height={16} count={3} /></h2>
                    <p className="date center">
                        <span><Skeleton height={14} width={100} /></span>
                    </p>
                </div>
            </div>
        ));
    
}
export default FantastTipsSkeleton;