import React from "react";
import { Link } from "react-router-dom";
import Menus from "./Menus";
const Navigation = () => {
    return (
        <div className="main-navigation dark:dark-navigation-bg">
            <div className="site-container">
                <div className="nav-wrap">
                    <div className="logo">
                        <Link to="/">
                            <img src="/assets/media/CRICLYSIS.svg" alt="Logo" />
                        </Link>
                    </div>
                    <div className="nav">
                        <Menus />
                    </div>
                    <div className="site-color-switcher">
                        <div>
                            <a 
                                className="login-btn" 
                                href="https://cricket-experts.cricdiction.com/log-in/?redirect_to=/dashboard" 
                                target="_blank" 
                                rel="noreferrer">Member Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Navigation;