import React, {useEffect, useState } from "react";

export default function Profile() {
    //  const [user, setUser] = useState([]);
    //  const [userMeta, setUserMeta] = useState([]);
     const [loading, setLoading] = useState(false);
 useEffect(() => {
  setLoading(true);
    async function fetchData() {
      var apiHost = 'https://cricket-experts.cricdiction.com/wp-json';
      fetch(apiHost + '/jwt-auth/v1/users/me/5?username='+localStorage.getItem('nicename'), {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
              'Authorization' : 'Bearer ' +  localStorage.getItem('jwt_token')
            },
        })
        .then((response) => response.json())
        .then((responseJson) => {
          // setUser(responseJson.user);
          // setUserMeta(responseJson.user_meta);
          // console.log(user);
          // console.log(userMeta);
          console.log(responseJson);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
    fetchData();
    
  }, []);



  return (
    <div className="container">
      <h1>Profile</h1>
      <p>{loading ? '' : ''}</p>
    </div>
  )
}
