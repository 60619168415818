import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DiscussionEmbed } from 'disqus-react';

const DiscussionSingle = () => {
    const params = useParams();
    const [singleDiscussion, setSingleDiscussion] = useState({});
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts/'+params.id)
        .then(response => response.json())
        .then(singleDiscussion => {
            setSingleDiscussion(singleDiscussion);
            // console.log(singleDiscussion)
            setLoader(true);
        })
    }, [params.id]);

    return(
        <div className="page-single-discussion">
            <div className="page-title single-discussion-page-title">
                <div className="site-container">
                    <div className="entry-title">
                        { loader ? <h1 dangerouslySetInnerHTML={{ __html:singleDiscussion.title.rendered }}></h1>  : '' }
                        { loader ? <p className="date">{ moment(singleDiscussion.date).format("D MMM, YYYY") }</p> : '' }
                    </div>
                </div>
            </div>
            <div className="discussion-wrap">
                <div className="site-container">
                    <DiscussionEmbed
                        shortname='cricdiction'
                        config={
                            {
                                url: 'https://cricdiction.com/'+ params.slug,
                                identifier: params.id,
                            }
                        }
                    />
                </div>
            </div>
        </div>
    )
}
export default DiscussionSingle;