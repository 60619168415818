import React, { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import DiscussionSkeleton from "../../Skeletons/DiscussionSkeleton";

const Discussion = () => {
    const [discussions, setDiscussions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');

    var myInit = {
        method: 'GET',
        headers: myHeaders,
    };
// https://www.cricdiction.com/wp-json/wp/v2/posts?categories=476&per_page=6
    useEffect(() => {
        fetch('https://www.freecricprediction.com/wp-json/wp/v2/posts?categories=40&per_page=6', { cache: 'no-store' })
        .then(response => response.json())
        .then(discussions => {
            setDiscussions(discussions);
            setIsLoading(false);
        })
    }, []);
  
    return (
        <div className="discussion-post-slider">
            <div className="site-container">
                <div className="section-title">
                    <h1 className="title">Live Cricket Prediction, Tips, In-play Session</h1>
                </div>
                {isLoading && <div className="discussion-skeleton"><DiscussionSkeleton items={3} /></div>}
                <Swiper
                    spaceBetween={18}
                    slidesPerView={3}
                    breakpoints={{
                        280: { slidesPerView: 1 },
                        360: { slidesPerView: 1 },
                        540: { slidesPerView: 2 },
                        768: { slidesPerView: 2 },
                        900: { slidesPerView: 3 },
                        1200: { slidesPerView: 3 },
                    }}
                >
                    { discussions.map((discussion) => (
                        <SwiperSlide key={discussion.id}>
                            <div className="discussion-item">
                                <Link to={'/'+discussion.id+'/'+discussion.slug}>
                                    <div className="dateTime">
                                        <p className="date">{ moment(discussion.date).format("D MMM YYYY") }</p>
                                        <p className={"live-icon " + ( (moment().format('D') ===  moment(discussion.date).format('D')) ? '' : 'hidden') }>
                                            <img src="/assets/media/live.gif" alt="" />
                                        </p>
                                    </div>
                                    <h2 className="title" dangerouslySetInnerHTML={{ __html:discussion.title.rendered }}></h2>
                                    <div className="discussion-footer-wrap">
                                        <p className="excerpt">Enter the chat room {`>`}</p>
                                        <div className="hour-ago"><span>{moment(discussion.date).fromNow()}</span></div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
export default Discussion;
