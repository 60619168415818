import React from "react";
import CurrentEventMatch from "./CurrentMatch/CurrentEventMatch";
import Discussion from "./Discussion/Discussion";
import FantasyTips from "./FantasyTips/FantasyTips";
const Home = () => {
    return (
        <div>   
            <CurrentEventMatch />
            <Discussion />
            <FantasyTips />
        </div>
    )
}
export default Home;