import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import SingleFantastTipsSkeleton from "../Skeletons/SingleFantasyTipsSkeleton";
const SingleFantasyTips = () => {
    const [singleTips, setSingleTips] = useState({});
    const [loader, setLoader] = useState(false);
    const params = useParams();

    useEffect(() => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts/'+params.id)
        .then (res => res.json())
        .then(singleTips => {
            setSingleTips(singleTips);
            setLoader(true);
        })
    }, [params.id])

    return (
        <>
            {loader ? 
                <div className="page-single-fantasy-tips">
                    <div className="page-title single-tips-page-title">
                        <div className="site-container">
                            <div className="entry-title">
                                <h1 className="title" dangerouslySetInnerHTML={{ __html:singleTips.title.rendered }}></h1>
                                <p className="date">
                                    <span>{moment(singleTips.date).format("D MMM YYYY")}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="site-container">
                        <div className="tips-main">
                            <div className="featured-image center">
                                <img src={singleTips.yoast_head_json.og_image[0].url ?? '..//..//assets/media/550.png'} alt="" />
                            </div>
                            <div className="tips-content" dangerouslySetInnerHTML={{ __html:singleTips.content.rendered }}></div>
                        </div>
                    </div>
                    
                </div>
        
                :
                <div className="site-container">
                    <SingleFantastTipsSkeleton items={1} />    
                </div>
            }
        </>
        
    );
}
export default SingleFantasyTips;