import React, { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import FantastTipsSkeleton from "../../Skeletons/FantasyTipsSkeleton";
const FantasyTips = () => {
    const [tips, setTips] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        fetch('https://www.cricdiction.com/wp-json/wp/v2/posts?categories=1063&per_page=4')
        
        .then(response => response.json())
        .then(tips => {
            setTips(tips);
            setIsLoading(false);
        })
    }, []);

    return (
        <div className="fantasy-tips">
            <div className="site-container">
                <div className="title-wrap">
                    <h1 className="title">Fantasy Tips</h1>
                    <Link to="/" className="link">
                        <span>view all</span>
                    </Link>
                </div>
                <div className="tips-wrap">
                    {isLoading && <FantastTipsSkeleton items={4} />}
                    { tips.map((item) => (
                        <div className="tips-item" key={item.id}>
                            <Link to={'/fantasy-cricket/' + item.id + '/' + item.slug} className="tips-link">
                                <img src={item.yoast_head_json.og_image[0].url ?? '..//..//assets/media/550.png'} alt="" />
                                <h2 className="title" dangerouslySetInnerHTML={{ __html:item.title.rendered }}></h2>
                                <p className="date">
                                    <span>{moment(item.date).format("D MMM YYYY")}</span>
                                </p>
                            </Link>

                            <a href={item.link} className="tips-link">
                                
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default FantasyTips;