import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Components/Auth/Login";
import Profile from "./Components/Auth/Profile";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import DiscussionSingle from "./Components/SinglePost/Discussion/DiscussionSingle";
import Footer from "./Components/Footer/Footer";
import EventSingle from "./Components/SinglePost/Event/EventSingle";
import SingleFantasyTips from "./Components/SinglePost/SingleFantasyTips";

const Routers = () => {
    return (
        <BrowserRouter>
            <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="event/:id/:slug" element={<EventSingle />} />
                    <Route path="/:id/:slug" element={<DiscussionSingle />} />
                    <Route path="fantasy-cricket/:id/:slug" element={<SingleFantasyTips />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/profile" element={<Profile />} />
                </Routes>
            <Footer />
        </BrowserRouter>
    )
}
export default Routers;